<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="24" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div class="text item">
            <el-button style="float: left; padding: 3px 0" type="text" @click="onReturnAudio">返回音频稿件列表</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>更新音频稿件信息</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateAudioPost">更新</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="form.title" style="width: 70%; padding-right: 2px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.description" type="textarea" style="width: 70%; padding-right: 2px" />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { getAudioPost } from '@/api/audio'

export default {
  name: 'EditAudio',
  data() {
    return {
      form: {
        title: null,
        description: null
      }
    }
  },
  created() {
    document.title = '编辑音频稿件'

    const audioId = this.$route.params.audioId
    getAudioPost(audioId).then(res => {
      if (res.code === 0) {
        const audioInfo = res.data
        this.form.title = audioInfo.title
        this.form.description = audioInfo.description
      } else {
      }
    })
  },
  methods: {
    onReturnAudio() {
      this.$router.push('/post/list/audio')
    },
    onUpdateAudioPost() {
      this.$notify({
        message: '接口待实现',
        type: 'info',
        duration: 3000
      })
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
